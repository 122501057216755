import React, { useEffect } from 'react';
import { Route, Routes } from 'routing';
import { isAdminPortal, hardReload } from 'utils';
import { Spin } from 'library'; 
import { local } from 'services';
import { useSelector } from 'react-redux';

const AdministrationApp = React.lazy(() => import('./administration'));
const LandingApp = React.lazy(() => import('./landing'));
const LinincidentApp = React.lazy(() => import('./lin/incident'));
const LinLinApp = React.lazy(() => import('./lin/lin'));
const LinIncidentReportApp = React.lazy(() => import('./lin/incidentreport'));
const linkToNewAdmin = `https://admin.${process.env.REACT_APP_DOMAIN}`;

const App = () => {
    const { isApiVersionUpdated } = useSelector(state => state.layout);

    useEffect(() => {
        const lastApiVersion = local.apiVersion();
        if (isApiVersionUpdated && isApiVersionUpdated !== lastApiVersion) {
            // eslint-disable-next-line no-console
            console.log('New Version Detected:', isApiVersionUpdated);
            local.apiVersion(isApiVersionUpdated);
            hardReload();
        }
    }, [isApiVersionUpdated]);

    if (isAdminPortal) {
        return <AdministrationApp />;
    }

    function RedirectToNewAdmin() {
        window.location.replace(linkToNewAdmin);
    }

    return (
        <React.Suspense fallback={<Spin className="center" />}>
            <Routes>
                <Route
                    path="/admin/*"
                    exact={false}
                    element={<RedirectToNewAdmin />}
                />
                <Route
                    path="/incident/*"
                    element={<LinincidentApp />}
                />
                <Route
                    path="/lin/*"
                    element={<LinLinApp />}
                />
                <Route
                    path="/incidentreport/*"
                    element={<LinIncidentReportApp />}
                />
                <Route
                    path="/*"
                    element={<LandingApp />}
                />
            </Routes>
        </React.Suspense>
    );
};

export default App;
