import variables from './variables.module.scss';

const antdTheme = {
    theme: {
        token: {
            borderRadius: parseInt(variables.borderRadius, 10),
            colorBgBase: variables.colorBgBase,
            colorError: variables.colorError,
            colorInfo: variables.colorInfo,
            colorPrimary: variables.colorPrimary,
            colorSuccess: variables.colorSuccess,
            colorTextBase: variables.colorTextBase,
            colorWarning: variables.colorWarning,
            controlHeight: parseInt(variables.controlHeight, 10),
            fontFamily: variables.fontFamily,
            fontSize: parseInt(variables.fontSize, 10),
            sizePopupArrow: parseInt(variables.sizePopupArrow, 10),
            sizeStep: parseInt(variables.sizeStep, 10),
            sizeUnit: parseInt(variables.sizeUnit, 10),
            colorBorder: variables.colorBorder,
            activeShadow: '0 0 0 2px rgba(5,145,255,0.1)',
        },
        components: {
            Button: {
                algorithm: true, 
            },
            Input: {
                algorithm: true,
            },
            Select: {
                algorithm: true,
            }
        },
    },
    // renderEmpty: () => <Empty />,
    prefixCls: 'ant',
};

export { antdTheme };
