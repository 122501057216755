import React from 'react';
import PropTypes from 'prop-types';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

const Help = ({ children, placement = 'top' }) => (
    <Tooltip
        placement={placement}
        title={children}
    >
        <QuestionCircleFilled />
    </Tooltip>
);

Help.propTypes = {
    children: PropTypes.any.isRequired,
    placement: PropTypes.string,
};

export { Help };
