import React from 'react';
import PropTypes from 'prop-types';
import { Link as BaseLink } from 'react-router-dom';

// @TODONEXT move to routing?

const Link = ({ to, disabled = false, children, bold = false, className = '', state = {}, ...rest }) => {
    const classNm = `${bold ? 'bold' : ' ' } ${ className}`;
    
    if (disabled) {
        return (
            <div 
                className={classNm}
                {...rest}
            >
                {children}
            </div>
        );
    }
    
    return (
        <BaseLink 
            {...rest}
            to={to.toString()}
            state={state}
            className={classNm}
        >
            {children}
        </BaseLink>
    );
};

Link.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired, 
    disabled: PropTypes.bool, 
    children: PropTypes.any.isRequired,
    bold: PropTypes.bool,
    className: PropTypes.string,
    state: PropTypes.object
};

export { Link };
