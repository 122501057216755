import { AUTH_TYPE } from 'security/constants';

export const LOCATION_TYPE = {};

export const REGISTRATION_FIELD_TYPE = {
    TEXT: 1,
    SELECT: 2
};

export const LOCATION_FLOW_TYPE = {
    EMPLOYER: 'EMPLOYER',
    EVENT: 'EVENT',
    HOUSEHOLD: 'HOUSEHOLD'
};

export const LOCATION_CALLBACKS = {
    NURSE: 1,
    DIGITAL: 0
};

export const LOCATION_CONTENT_MANAGEMENT_SLUGS = {
    HOLD: { 
        key: 'hold', 
        title: 'Hold Page', 
        module: 'pandemic',
        help: 'Manage the message that users will see when their account is placed on hold (i.e. they have failed a screening). You can add details of what process they should follow and who they should contact.'
    },
    OK: { 
        key: 'screen', 
        title: 'OK Page', 
        module: 'autoscreen'
    },
    TEST: { 
        key: 'test', 
        title: 'Testing Page', 
        module: 'test'
    },
    MEDICAL: { 
        key: 'medical', 
        title: 'Medical Consent', 
    },
    TESTING_REMINDERS: { 
        key: 'testingReminders', 
        title: 'Testing Reminders', 
    },
};

export const LOCATION_TRANS_TYPES = [
    'hold',
    'test',
    'screen', 
    'vaccination',
    'welcome',
    'protocol',
    'medical',
    'testingReminders'
];

export const LOCATION_DEFAULT = {
    $authType: AUTH_TYPE.SSO,
    $authMixedMode: false,
    $authIsByEmail: true, 
    exposureDays: '14',
    flowType: LOCATION_FLOW_TYPE.EMPLOYER,
    emergencyNumber: '911',
    emailSendingEnabled: false,
    smsSendingEnabled: false,
    isSpecificSymptomsEnabled: 1,
    timezone: 'US/Arizona',
    temperatureUnit: 'F',
    usersLimit: 100000,
    isScreeningDetailsEnabled: 0,
    title: '',
    screenValidityHours: '25',
    syncScreenings: 1,
    tokenValidityHours: 4,
    areExposureQuestionsRequired: 1,
    modules: [],
    questions: [],
    forUser: [],
    jobTitles: [],
    contentManagement: [],
    $loading: false,
    $modules: {},
    children: [],
    reportableChildren: []
};

export const LocationAuthOptions = [
    { value: AUTH_TYPE.OTP, label: 'Phone + Verification Code' },
    { value: AUTH_TYPE.PHONE, label: 'Phone + Password' },
    { value: AUTH_TYPE.EMAIL, label: 'Email + Password' },
    { value: AUTH_TYPE.SSO, label: 'SSO (Azure Active Directory)' }
];

export const LOCATION_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 0,
};

/* 
const buildLocationUrl = ({ useSubdomain, subdomain, locationHash }) => {
    const CurrentHost = window.location.host;
    const AppHost = process.env.REACT_APP_DOMAIN || CurrentHost;

    const dd = useSubdomain ? `${useSubdomain }.` : '';
    const hh = !subdomain && locationHash ? `/${locationHash }` : '';
    return `https://${dd}${AppHost}${hh}`;
}; */
