import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(calendar);
dayjs.extend(utc, { parseToLocal: false });
dayjs.extend(localeData);
dayjs.extend(timezone);
dayjs.extend(duration);

dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        m: 'a minute',
        mm: '%d min',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years'
    },
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
    }
});

export const formats = {
    date: {
        in: 'MM/DD/YYYY',
        out: 'YYYY-MM-DD'
    },
    time: {
        in: 'h:mm a',
        out: 'HH:mm',
    },
    dateTime: {
        in: 'MM/DD/YYYY h:mm A',
        out: 'YYYY-MM-DD HH:mm',
    },
    dateTimeSec: {
        in: 'MM/DD/YYYY h:mm:ss A',
        out: 'YYYY-MM-DD HH:mm:ss',
    }
};

export const dateInFuture = date => date && date > dayjs().add(0, 'day');
export const dateInPast = data => data && data < dayjs().startOf('day');
export const formatTimeIn = time => dayjs(`2020-01-01 ${time}`).format(formats.time.in);
export const formatDateTimeIn = dateTime => (Object.isFunction(dateTime?.format) 
    ? dateTime.format(formats.dateTime.in) : dayjs(dateTime).format(formats.dateTime.in));
export const formatDateTimeOut = dateTime => dayjs(dateTime).format(formats.dateTime.out);
export const formatDateOut = date => dayjs(date).format(formats.date.out);
export const formatDateIn = date => (date ? dayjs(date).format(formats.date.in) : '');

export const checkAndFormatDateIn = date => !!date && dayjs(date).format(formats.date.in);

const zeroPad = (value) => {
    if (value < 10) {
        return `0${ value}`;
    } 
        return value;
};
export const formatTime = (seconds, format) => {
    if (format === 'humanize') {
        return dayjs.duration(seconds * 1000).humanize();
    }
    
    let delta = seconds;
    const chunks = [];
    const days = Math.floor(delta / 86400);
    if (days) {
        chunks.push([days, 'd']);
    }
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    if (hours) {
        chunks.push([hours, 'h']);
    }
    delta -= hours * 3600;
    const mins = Math.floor(delta / 60) % 60;
    if (mins) {
        chunks.push([mins, ' min']);
    }
    delta -= mins * 60;
    const sec = delta % 60;
    if (sec || mins) {
        chunks.push([sec, 's']);
    }
    const show = chunks.slice(0, 2);

    if (format === 'minsOnly') {
        return `${zeroPad(mins)}:${zeroPad(sec)}`;
    }
    return show.map(([val, label]) => val + label).join(' ');
};

export const getUserTimezone = () => dayjs.tz.guess();

export const setDateLocale = lang => dayjs.locale(lang);

export const isPastDate = (dateStr) => {
    const date = dayjs(dateStr);
    if (!date.isValid()) {
        return true;
    }
    return dayjs().diff(date) > 0;
};

export const now = dayjs;
