// const notification = require('antd/lib/notification').default;
import { notification } from 'library';

const messages = {
    saved: 'Saved successfully',
    created: 'Created successfully',
    deleted: 'Deleted successfully',
};

const duration = 5; // seconds

class ShowMessage {
    static saved({ msg } = {}) {
        notification.success({
            message: msg || messages.saved,
            duration,
        });
    }

    static info(msg) {
        notification.info({
            message: msg,
            duration,
        });
    }

    static created({ msg } = {}) {
        notification.success({
            message: msg || messages.created,
            duration,
        });
    }

    static deleted({ msg } = {}) {
        notification.success({
            message: msg || messages.deleted,
            duration,
        });
    }

    static custom({ msg, duration: customDuration, type }) {
        notification[type]({
            message: msg,
            duration: customDuration || duration,
        });
    }
}

export { ShowMessage };
