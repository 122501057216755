import './LocationSelect.scss';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Multiselect, Highlight } from 'library';
import { LocationService } from 'modules/location';
import { debounce } from 'utils';

const MinCharsToSearch = 4;

const Searching = () => <div className="empty">Searching...</div>;
const Tooltip = () => <div className="empty">Please enter at least {MinCharsToSearch} characters</div>;
const NoData = () => <div className="empty">No Data Found</div>;

const LocationSelect = ({
    value, onChange, 
    multiple,
    filters = {},
    variant,
    disabled = false,
    maxTagCount = 'responsive',
    service = LocationService, 
    valuePropName = 'id'
}) => {
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState();
    const [searchBy, setSearchBy] = useState();

    const onBeforeChange = (val, locations) => {
        if (onChange) {
            // eslint-disable-next-line no-shadow
            const opts = locations.map(({ label, value, ...rest }) => rest);
            onChange(val, opts);
        }
    };

    const executeSearch = useCallback(async (searchTitle) => {
        setSearchBy(searchTitle);
        if (!searchTitle || searchTitle.length < MinCharsToSearch) {
            return;
        }
        setSearching(true);
        service.options({ title: searchTitle, ...filters })
            .then(({ data }) => {
                const ops = data
                    .filter(item => !!item.title)
                    .map(item => ({
                        ...item,
                        value: item[valuePropName],
                        label: (
                            <>
                                <Highlight
                                    search={searchTitle}
                                    text={item.title}
                                />
                                {' '}
                                {!!item.code && <code>({item.code})</code>}
                            </>)
                    }));

                setOptions(ops);
            })
            .finally(() => setSearching(false));
    }, [service, filters, valuePropName]);

    useEffect(() => {
        const loadSelectedOptions = async (ids) => {
            setLoading(true);
            service.options({ ids })
                .then(({ data }) => {
                    const res = data.map(item => ({
                        ...item,
                        value: item[valuePropName],
                        label: (
                            <>
                                {item.title}
                                {' '}
                                {!!item.code && <code>({item.code})</code>}
                            </>
                        )
                    }));
                    setSelectedValue(res);
                })
                .finally(() => setLoading(false));
        };
        let found = false;
        if (multiple && value?.length) {
            found = options
                .filter(item => value.includes(item.value)) === value.length;
            if (!found) {
                loadSelectedOptions(value);
                return;
            }
        } else if (!multiple && value) {
            found = options.find(item => item[valuePropName] === value);
            if (!found) {
                loadSelectedOptions([value]);
                return;
            }
        }

        if (found) {
            setSelectedValue(found);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onSearch = useMemo(() => debounce(executeSearch, 800), [executeSearch]);

    return (
        <Multiselect
            mode={multiple ? 'multiple' : 'single'}
            value={selectedValue}
            onChange={onBeforeChange}
            popupMatchSelectWidth={false}
            showSearch
            allowClear
            onSearch={onSearch}
            options={options}
            loading={loading}
            variant={variant}
            disabled={disabled}
            placeholder={multiple ? 'Search for Locations' : 'Search for Location'}
            popupClassName="location-multiselect-dropdown"
            maxTagCount={maxTagCount}
            valuePropName={valuePropName}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={(menu) => {
                if (searching) {
                    return <Searching />;
                }
                if (!searchBy) {
                    return <Tooltip />;
                }
                if (!options.length) {
                    return <NoData />;
                }
                return menu;
            }}
            notFoundContent={NoData}
        />
    );
};

LocationSelect.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    variant: PropTypes.bool,
    disabled: PropTypes.bool,
    filters: PropTypes.object,
    maxTagCount: PropTypes.string,
    multiple: PropTypes.bool,
    service: PropTypes.func,
    valuePropName: PropTypes.string
};

export { LocationSelect };
