import axios from 'axios';
import { isAdminPortal, getUserTimezone } from 'utils';
import { getToken, signOut } from 'security';
import store from 'modules/store';
import { LayoutConstants } from 'modules/layout';
import { apiList } from './api.service.list';
import apiDownload from './api.service.download';

const ApiBaseUrl = process.env.REACT_APP_API;
const AppVersion = process.env.REACT_APP_VERSION;
const AuthPrefix = 'Bearer ';
const timezone = getUserTimezone();

function ab2str(buf) {
    const enc = new TextDecoder('utf-8');
    const arr = new Uint8Array(buf);
    const res = enc.decode(arr);
    const json = JSON.parse(res);
    return json;
}

export const ApiHeaders = {
    'X-FrontEnd-Version': AppVersion,
    'X-FrontEnd-Admin-Portal': isAdminPortal ? 1 : 0
};

const api = axios.create({
    baseURL: ApiBaseUrl,
    headers: {
        Accept: 'application/json',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store',
        ...ApiHeaders
    },
    transformRequest: data => JSON.stringify(data),
    timeout: 10 * 60 * 1000,
    responseType: 'json',
});

api.list = (...args) => apiList(api, ...args);

api.download = (...args) => apiDownload(api, ...args);

api.token = () => getToken();

api.upload = (url, formData) => api.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    transformRequest: data => data
});

const InterceptorRequest = async (config) => {
    const token = await getToken();
    const headers = {
        Authorization: `${AuthPrefix}${token}`,
        timezone,
    };
    return {
        ...config,
        headers: {
            ...config.headers,
            ...headers
        },
    };
};

const InterceptorResponseSuccess = (response) => {
    const contentType = response.headers['content-type'];
    if (contentType !== 'application/json') {
        const disposition = response.headers['content-disposition'] || '';
        const name = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
        return {
            file: response.data,
            filename: name && name.length > 1 ? name[1] : 'download.txt',
            contentType
        };
    }

    const $code = response.status;
    const { method: $method, url: $path } = response.config;
    
    const $apiVersion = response.headers['x-api-version'];
    if ($apiVersion) {
        store.dispatch({
            type: LayoutConstants.API_VERSION,
            apiVersion: $apiVersion
        });
    }
    
    if (response.data) {
        const { data } = response;
        const res = {
            ...data,
            $code: data.code || $code,
            $method,
            $path,
        };
        return res;
    }

    return (response);
};

const InterceptorResponseError = async (error) => {
    const response = error.response || {};
    let responseBody = response.data;
    const $code = response.status;
    const request = error.config;
    const { method: $method, url: $path } = request;

    let err = { $code, $method, $path };

    if (!$code) {
        err = { $path: '/', $code: 503 }; // Service Unavailable
    }

    if ($code === 401) {
        if ($path !== '/token') {
            signOut();
        }
        return Promise.reject();
    }

    if (request.responseType === 'arraybuffer') {
        responseBody = ab2str(responseBody);
    }
    
    if (responseBody) {
        if (responseBody.errors) {
            err.message = Object.values(responseBody.errors).join(' ');
        } else if (responseBody.messages) {
            err.message = Object.values(responseBody.messages).join(' ');
        } else if (responseBody.message) {
            err.message = responseBody.message;
        } else {
           err.message = responseBody.error;
        }
    }
    
    if (!err.message && error.message) {
        err.message = error.message;
        err.description = error.description || '';
    }

    if (!err.message) {
        err.message = JSON.stringify(error);
    }

    return Promise.reject(err);
};

api.interceptors.request.use(InterceptorRequest, Promise.reject);
api.interceptors.response.use(InterceptorResponseSuccess, InterceptorResponseError);

export { api };
