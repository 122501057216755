import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import { stopEvent } from 'utils';

const FilterText = ({
    selectedKeys = [], setSelectedKeys,
    confirm, clearFilters, label = '', visible
}) => {
    const ref = React.useRef();
    const [value, setValue] = useState(false);

    useEffect(() => {
        if (ref.current) {
            setTimeout(() => {
                ref.current.select();
            });
        }
    }, [ref, visible]);

    useEffect(() => {
        const defaultValue = selectedKeys?.length === 2 ? selectedKeys[1] : undefined;
        setValue(defaultValue);
    }, [selectedKeys]);

    const handleSearch = () => {
        if (value) {
            setSelectedKeys(['LIKE', value]);
            confirm();
        } else {
            clearFilters();
        }
    };

    const onPressEnter = (e) => {
        stopEvent(e);
        handleSearch();
    };

    const handleClearFilters = () => {
        setValue();
        clearFilters();
        confirm();
    };

    return (
        <div>
            <Input
                ref={ref}
                placeholder={`Search ${label}`}
                value={value}
                onChange={e => setValue(e.target.value)}
                onPressEnter={onPressEnter}
            />
            <div className="ant-table-filter-dropdown-btns">
                <Button
                    type="link"
                    size="small"
                    onClick={handleClearFilters}
                    className="ant-table-filter-dropdown-link clear"
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={handleSearch}
                    className="ant-table-filter-dropdown-link confirm"
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

FilterText.propTypes = {
    selectedKeys: PropTypes.array,
    label: PropTypes.string, 
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired, 
    clearFilters: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
};

export default FilterText;
