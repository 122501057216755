import './FormItem.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { Help } from '../Help';

const { Item } = Form;

const FormItem = ({ children, tooltip, label, placement = 'top', ...rest }) => (
    <Item
        label={label && (
            <>
                <span>{label}</span>
                {tooltip && (
                    <Help 
                        placement={placement}
                    >
                        {tooltip}
                    </Help>
                )}
            </>
        )}
        {...rest}
    >{children}
    </Item>
);

FormItem.propTypes = {
    tooltip: PropTypes.any,
    children: PropTypes.any.isRequired,
    label: PropTypes.any,
    placement: PropTypes.string,
};

export { FormItem };
