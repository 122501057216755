import constants from './layout.constants';

const parseVersion = x => parseInt(x.replace('.', ''), 10);
const AppVersionInt = parseVersion(process.env.REACT_APP_VERSION);

const initialState = Object.freeze({ 
    menuOpened: false,
    vaccMenuOpened: false,
    actionStatusesOpened: false,
    redirectedToHold: false,
    submenu: '',
    isApiVersionUpdated: false
});

const layout = (state = initialState, action = {}) => {
    switch (action.type) {
        case constants.MENU_OPEN: {
            return {
                ...state,
                menuOpened: action.menu,
            };
        }
        case constants.MENU_CLOSE: {
            return {
                ...state,
                menuOpened: false,
            };
        }
        case constants.ACTION_STATUSES_OPEN: {
            return {
                ...state,
                actionStatusesOpened: action.opened,
            };
        }
        case constants.INSTALL_BANNER_HIDE: {
            return {
                ...state,
                installBannerHidden: true,
            };
        }
        case constants.ADMIN_SUBMENU: {
            return {
                ...state,
                submenu: action.submenu,
            };
        }
        case constants.ADMIN_TABLE_PARAMS: {
            return {
                ...state,
                params: action.params,
            };
        }
        case constants.API_VERSION: {
            const ApiVersion = parseVersion(action.apiVersion);
            if (ApiVersion > AppVersionInt) {
                return {
                    ...state,
                    isApiVersionUpdated: ApiVersion,
                };
            }
            return state;
        }
        default:
            return state;
    }
};

export default layout;
