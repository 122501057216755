import { ModulesConfig } from 'modules/module';

const SETTINGS_KEYS = {
    help: 'HELP_CONTENT',
    privacy: 'PRIVACY_CONTENT',
    terms: 'TERMS_CONTENT',
};

Object.keys(ModulesConfig).forEach((key) => {
    const keyupper = key.toUpperCase();
    SETTINGS_KEYS[keyupper] = `${keyupper }_INFO_CONTENT`;
    SETTINGS_KEYS[`${keyupper}_ACTIVE`] = `${keyupper }_INFO_CONTENT_ACTIVE`;
});

export { SETTINGS_KEYS };
