import React, { useState, useEffect, useRef } from 'react';
import './ShowMore.scss';
import PropTypes from 'prop-types';
import { Button } from '../Button';

const ShowMore = ({ className = '', children, height = 53 }) => {
    const [open, setOpen] = useState(false);
    const [hasMOre, setHasMOre] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const elHeight = ref.current?.offsetHeight || 0;
        setHasMOre(elHeight >= height);
    }, [height, children]);

    const component = typeof children === 'object' ? children : <div dangerouslySetInnerHTML={{ __html: children }} />;

    return (
        <div className={`show-more-container ${hasMOre ? 'has-more' : ''} ${open ? ' open' : ''}`}>
            <div
                className={`text ${className}`}
                style={{ maxHeight: height }}
                ref={ref}
            >
                {component}
            </div>
            <Button type="link" onClick={() => setOpen(!open)}>
                {open ? 'Show Less' : 'Show More'}
            </Button>
        </div>
    );
};

ShowMore.propTypes = {
    height: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
};

export { ShowMore };
