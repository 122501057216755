import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'library';
import { LocationConfirmUpdateModal } from './confirmUpdateModal';
import UpdatableFields, { FieldsToKeep } from './config';

const LocationForm = React.forwardRef(({ 
    onValuesChange, 
    onFinish, 
    value = [],
     ...rest 
}, ref) => {
    const { children } = useSelector(state => state.location);
    const [changedValues, setChangedValues] = useState({});
    const [visible, setVisible] = useState(false);
    const updatableFieldKeys = Object.keys(UpdatableFields);
    
    const onBeforeChange = (changed, all) => {
        const newFields = { ...changed };
        const changedKeys = Object.keys(changed);
        changedKeys.forEach((key) => {
            if (FieldsToKeep.includes(key)) {
                newFields[key] = all[key]; 
            }
        });

        setChangedValues(val => ({ ...val, ...newFields }));
        if (onValuesChange) {
            onValuesChange(newFields, all);
        }
    };

    const $fieldsToUpdate = Object.keys(changedValues)
        .filter(key => updatableFieldKeys.includes(key));

    const changedFieldsTitles = $fieldsToUpdate
        .map(key => UpdatableFields[key]);

    const onBeforeFinish = (vals) => {
        if (children.length && changedFieldsTitles.length) {
            setVisible(true);
        } else if (onFinish) {
            onFinish(vals);
        }
    };

    const onConfirm = ($toLocationIds) => {
        const promise = onFinish({ 
            ...changedValues, 
            $toLocationIds,
        });
        if (promise && promise.then) {
            promise.then(() => {
                setChangedValues({});
            });
            return promise;
        }
        return Promise.resolve({});
    };
    
    return (
        <>
            {visible && (
                <LocationConfirmUpdateModal 
                    setVisible={setVisible}
                    message={(
                        <>
                            Update
                            {' '}
                            <span className="info">
                                {changedFieldsTitles.join(', ')}
                            </span> in the current location?
                        </>
                    )}
                    onConfirm={onConfirm}
                />
            )}
            <Form
                ref={ref}
                onValuesChange={onBeforeChange}
                onFinish={onBeforeFinish}
                value={value}
                {...rest}
            />
        </>
    );
});

LocationForm.propTypes = {
    value: PropTypes.array,
    disabled: PropTypes.bool,
    onValuesChange: PropTypes.func,
    onFinish: PropTypes.func
};

export { LocationForm };
