import './clearFilters.scss';
import React, { useEffect, useState, useContext } from 'react';
import { Button, Tooltip } from 'library';
import { FilterFilled } from '@ant-design/icons';
import Context from '../context';

const ClearFiltersButton = () => {
    const { fetchParams, clearFilters } = useContext(Context);
    const [disabled, setDisabled] = useState(true);
    const [visible, setVisible] = useState(false);

     useEffect(() => {
        const hasFilters = Object.values(fetchParams.filters).filter(x => !!x).length;
        setDisabled(!hasFilters);
    }, [fetchParams.filters]);
    
    const onClick = () => {
        clearFilters();
        setDisabled(true);
        setVisible(false);
    };

    const onMouseOver = () => {
        if (!visible) {
            setVisible(true);
        }
    };
    
    return (
        <Tooltip
            placement="bottom"
            title="Clear Filters"
            open={visible}
            onOpenChange={setVisible}
        >
            <Button
                className="clear-filters"
                type="link"
                onMouseOver={onMouseOver}
                onClick={onClick}
                icon={<FilterFilled />}
                disabled={disabled}
            >
                Clear Filters
            </Button>
        </Tooltip>
    );
};

export { ClearFiltersButton };
