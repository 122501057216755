import './Alert.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AlertBase } from 'antd';

const Alert = ({ 
    children = null, 
    description = null, 
    icon = null,
    closable = true, 
    showIcon = true, 
    type = 'info',
    ...rest 
}) => (
    <AlertBase
        {...rest}
        description={children || description}
        closable={closable}
        showIcon={showIcon}
        icon={icon}
        type={type}
    />
);

Alert.propTypes = {
    children: PropTypes.any,
    description: PropTypes.any,
    icon: PropTypes.any,
    closable: PropTypes.bool,
    showIcon: PropTypes.bool,
    type: PropTypes.string,
};

export { Alert };
