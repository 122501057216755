import './moduleInfo.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spin, Button } from 'library';
import { EditableContent } from 'routes/administration/containers';
import { SETTINGS_KEYS } from 'modules/settings';
import { ModulesConfig } from 'modules/module';
import { history } from 'routing';
import EditableContentTab from './EditableContentTab';

const ModuleInfo = ({ module, hasContent = true }) => {
    const { $isSysAdmin, $locationModules } = useSelector(state => state.user);

    const Module = ModulesConfig[module];
    const userHasAccess = $locationModules[module];
    const activeModule = SETTINGS_KEYS[`${module.toUpperCase()}_ACTIVE`];
    const inactiveModule = SETTINGS_KEYS[`${module.toUpperCase()}`];

    const onClick = () => {
        history.push('/support', { about: Module.title, config: 'module' });
    };

    return (
        <Spin spinning={false}>
            <div className="page-header">
                <h1>{Module.title} Module</h1>
            </div>

            {$isSysAdmin ? (
                <EditableContentTab
                    options={[
                        {
                            editable: $isSysAdmin,
                            tab: 'Active Content',
                            onClick,
                            name: activeModule,
                            key: `${module}-active-content`,
                            disabled: hasContent,
                            module
                        },
                        {
                            editable: $isSysAdmin,
                            tab: 'Inactive Content',
                            onClick,
                            name: inactiveModule,
                            key: `${module}-inactive-content`,
                            module
                        }
                    ]}
                />
            ) : (
                <div className={`page-scroll module-info module-info-${module}`}>
                    <EditableContent
                        name={userHasAccess ? activeModule : inactiveModule}
                        editable={false}
                        footer={(
                            <Button
                                type="primary"
                                onClick={onClick}
                            >
                                Contact Us
                            </Button>
                        )}
                    />
                </div>
            )}
        </Spin>
    );
};

ModuleInfo.propTypes = {
    module: PropTypes.string.isRequired,
    hasContent: PropTypes.bool
};

export default ModuleInfo;
