import PropTypes from 'prop-types';
import React, { useRef, useState, useLayoutEffect } from 'react';

import { Router as RouterBase } from 'react-router-dom';
import { history } from './history';

const Router = ({ basename = '', children = [] }) => {
    const historyRef = useRef();
    if (historyRef.current == null) {
        historyRef.current = history;
    }
    const currentHistory = historyRef.current;
    const [state, setState] = useState({
        action: currentHistory.action,
        location: currentHistory.location
    });

    useLayoutEffect(() => {
        currentHistory.listen(setState);
    }, [currentHistory]);

    return (
        <RouterBase
            basename={basename}
            location={state.location}
            navigationType={state.action}
            navigator={currentHistory}
        >
            {children}
        </RouterBase>
    );
};

Router.propTypes = {
    basename: PropTypes.string, 
    children: PropTypes.any
};

export { Router };
