import './Tree.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tree as TreeBase } from 'antd';
import { Spin } from '../Spin';
import { InputSearch } from '../InputSearch';

const filterUniqueFn = (item, idx, self) => self.indexOf(item) === idx;
const sortChildrenFn = (a, b) =>
    (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);

const sortParentFn = (a, b) => (a.parentLabel.toLowerCase() > b.parentLabel.toLowerCase() ? 1 : -1);

const Tree = ({
    multiple = false,
    loading = false,
    showSearch = false, 
    defaultExpandParent = false,
    itemLabel, searchLabel,
    options,
    height,
    onCheck,
    value = [],
    checkedKeys = [],
    ...rest
}) => {
    const SELECT_ALL_KEY = `All ${itemLabel}`;

    const defaultExpandedKeys = multiple ? [SELECT_ALL_KEY] : [];
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState(defaultExpandedKeys);
    const [treeData, setTreeData] = useState([]);
    const [visibleKeys, setVisibleKeys] = useState([]);
    const [filteredTreeData, setFilteredTreeData] = useState([]);

    useEffect(() => {
        if (options.length) {
            setVisibleKeys(options.map(i => i.value.toString()));
        }
    }, [options]);

    useEffect(() => {
        if (options.length && !treeData.length) {
            let data = [...options];
            if (data.length) {
                if (data[0].parentValue) {
                    data = options
                        .sort(sortParentFn)
                        .map(item => item.parentValue)
                        .filter(filterUniqueFn)
                        .map(parentValue => ({
                            key: parentValue,
                            title: options
                                .find(item => item.parentValue === parentValue)?.parentLabel,
                            children: options
                                .filter(item => item.parentValue === parentValue)
                                .sort(sortChildrenFn)
                                .map(item => ({
                                    key: item.value,
                                    title: item.label,
                                }))
                        }));
                } else {
                    data = options
                        .sort(sortChildrenFn)
                        .map(item => ({
                            key: item.value,
                            title: item.label,
                        }));
                }
            }
            if (multiple) {
                data = [{
                    title: 'Select All',
                    key: SELECT_ALL_KEY,
                    className: '_all',
                    children: data
                }];
            }

            setTreeData(data);
            setFilteredTreeData(data);
        }
    }, [SELECT_ALL_KEY, multiple, options, treeData]);

    const onSearch = (val) => {
        const text = val.toLowerCase();
        setSearchValue(text);
        if (!text) {
            setFilteredTreeData(treeData);
            setExpandedKeys(defaultExpandedKeys);
            setAutoExpandParent(false);
            setVisibleKeys(options.map(i => i.value.toString()));
        } else {
            const foundItems = {};
            const filterFn = item => item.label.toLowerCase().includes(text)
                || item.parentLabel?.toLowerCase().includes(text);

            const expKeys = [];
            options
                .filter(filterFn)
                .forEach((item) => {
                    if (item.parentValue) {
                        expKeys.push(item.parentValue);
                        foundItems[item.parentValue] = true;
                    }
                    foundItems[item.value] = true;
                });

            const foundKeys = Object.keys(foundItems);
            setVisibleKeys(foundKeys);
            if (multiple) {
                expKeys.push(SELECT_ALL_KEY);
            }

            const newTreeData = treeData
                .filter(item =>
                    foundKeys.includes(item.key)
                    || item.children
                        .find(child => foundKeys.includes(child.key.toString())))
                .map(item => ({
                    ...item,
                    children: item.children
                        .filter(child => foundKeys.includes(child.key.toString()))
                }));

            setFilteredTreeData(newTreeData);
            setExpandedKeys(expKeys);
            setAutoExpandParent(true);
        }
    };

    const beforeCheck = (values) => {
        const selectedLeafs = values.filter((key) => {
            const item = options.find(i => i.value === key);
            return item && item.isLeaf;
        });
        const oldInvisibleValues = checkedKeys.filter(key => !visibleKeys.includes(key.toString()));
        const newValues = [...oldInvisibleValues, ...selectedLeafs].unique();
        onCheck(newValues);
    };

    const loop = (data) => {
        if (!searchValue) {
            return data;
        }
        return data.map((item) => {
            const index = item.title.toLowerCase().indexOf(searchValue);
            const beforeStr = item.title.substr(0, index);
            const string = item.title.substr(index, searchValue.length);
            const afterStr = item.title.substr(index + searchValue.length);
            const title = index > -1 ? (
                <span>
                    {beforeStr}
                    <span className="tree-search-value">{string}</span>
                    {afterStr}
                </span>
            ) : (
                <span>{item.title}</span>
            );
            if (item.children) {
                return { ...item, title, key: item.key, children: loop(item.children) };
            }

            return {
                title,
                key: item.key,
            };
        });
    };

    const onExpand = (keys) => {
        setExpandedKeys(keys);
        setAutoExpandParent(false);
    };

    const stopBackspaceEvent = (e) => {
        if (e.keyCode === 8) {
            e.stopPropagation();
        }
    };

    return (
        <>
            {showSearch && (
                <InputSearch
                    onKeyDown={stopBackspaceEvent}
                    className="tree-input-search"
                    placeholder={searchLabel || `Search for ${itemLabel}`}
                    onSearch={onSearch}
                />
            )}
            <Spin spinning={loading}>
                <TreeBase
                    defaultCheckedKeys={value}
                    checkedKeys={checkedKeys}
                    virtual
                    className={`${multiple ? 'multiple' : ''}`}
                    treeData={loop(filteredTreeData)}
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={!multiple && autoExpandParent}
                    height={height}
                    checkable={!!onCheck}
                    selectable={!!rest.onSelect}
                    onCheck={onCheck && beforeCheck}
                    defaultExpandParent={defaultExpandParent}
                    {...rest}
                />
            </Spin>
        </>
    );
};
Tree.propTypes = {
    showSearch: PropTypes.bool,
    itemLabel: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    loading: PropTypes.bool,
    defaultExpandParent: PropTypes.bool,
    defaultExpandedKeys: PropTypes.array,
    height: PropTypes.number.isRequired,
    onCheck: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            parentValue: PropTypes.number,
            parentLabel: PropTypes.string,
        })
    ).isRequired,
    searchLabel: PropTypes.string,
    value: PropTypes.array,
    checkedKeys: PropTypes.array
};

export { Tree, TreeBase };
