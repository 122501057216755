// Data helpers
import dayjs from 'dayjs';

export const sortByString = prop =>
    (a, b) => (a[prop].toLowerCase() < b[prop].toLowerCase() ? -1 : 1);

export const sortByNumber = prop => (a, b) => (a[prop] > b[prop] ? -1 : 1);

export const sortByDate = prop => (a, b) => (dayjs(a[prop]).isBefore(dayjs(b[prop])) ? 1 : -1);

/// Not used
export const parseLinks = (inputText) => {
    const regHttp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    const regWww = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    const regMail = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    
    return inputText.replace(regHttp, '<a href="$1" target="_blank">$1</a>')
        .replace(regWww, '$1<a href="http://$2" target="_blank">$2</a>')
        .replace(regMail, '<a href="mailto:$1">$1</a>');
};

export const maskUsername = (val) => {
    if (val.includes('@')) {
        return val.replace(/^(.{1})[^@]+/, '$1***');
    }
    return `(xxx) xxx-${/.{4}$/.exec(val)[0]}`;
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / (k ** i)).toFixed(decimals))} ${sizes[i]}`;
};

export const formatPhone = (value) => {
    if (!value) return '';
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return value;
};

export const formatPhoneE164 = (value, code = '+1') => {
    if (!value) {
        return value;
    }
    let temp = value.toString();
    if (!temp.startsWith(code)) {
        temp = `${code}${temp}`;
    }
    return temp.replace(/[- ()*]/g, '');
};
