import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LocationService } from 'modules/location';
import { Button } from 'library';
import { showError } from 'utils';

const ButtonPosterDownload = ({ forceDownload = true, locationId, ...rest }) => {
    const [loading, setLoading] = useState(false);

    const onPosterDownload = (e) => {
        e.preventDefault();
        setLoading(true);
        LocationService.downloadPoster(locationId, forceDownload)
            .finally(() => setLoading(false))
            .catch(showError);
    };

    return (
        <Button
            onClick={onPosterDownload}
            loading={loading}
            {...rest}
        />
    );
};

ButtonPosterDownload.propTypes = {
    forceDownload: PropTypes.bool,
    locationId: PropTypes.number.isRequired
};

export { ButtonPosterDownload };
