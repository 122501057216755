import { isValidPhoneNumber } from 'react-phone-number-input';
import { RESERVED_SUBDOMAINS } from 'constants';
import { UserService } from 'modules/user';
import { LocationService } from 'modules/location';

const FieldTypes = {
    RADIO: 'RADIO',
    CHECKBOXES: 'CHECKBOX',
    TEMPERATURE: 'temperature',
    DATE: 'date',
    TEXTAREA: 'OPEN_ENDED',
    ADVANCED_RADIO: 'ADVANCED_RADIO',
};

const ValidationRules = ({
    name: { pattern: /^[a-zA-Z][^#&<>"~;$^%{}?]*$/i, message: 'Invalid characters.' },
    manychars: { pattern: /^.{2,}$/, message: 'Name should contain at least two characters.' },
    string: { pattern: /^[a-z0-9]+$/i, message: 'Invalid characters' },
    subdomain: { pattern: /^[a-zA-Z0-9-]+$/i, message: 'Only letters, digits and hyphens are allowed.' },
    zip: { pattern: /(^\d{5}$)/, message: 'Invalid Zip' },
    email: { type: 'email', message: 'Invalid Email' },
    number: { pattern: /^[0-9]*$/, message: 'Invalid characters' },
    required: { required: true, message: 'Required field' },
    url: { pattern: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/, message: 'Invalid Url' },
    domain: { pattern: /^[a-zA-Z0-9.]+\.[a-z]{2,4}$/i, message: 'Invalid Domain' },
    terms: {
        validator: async (_, value) => {
            if (!value) throw new Error('Should accept terms');
        }
    },
    emails: {
        pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g,
        message: 'Invalid Email',
    },
    password: {
        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        message: 'Password must be at least 8 characters including one uppercase letter, one lowercase letter and 1 number.'
    },
    passwordRepeat: ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The passwords do not match'));
        },
    }),
    phone: {
        validator: async (data, value) => {
            if (value) {
                if (isValidPhoneNumber(value)) return;
                throw new Error('Invalid Phone Number');
            }
        }
    },
    phoneUnique: ({ isFieldTouched }) => ({
        validator: async (data, value) => {
            if (value && isFieldTouched(data.field)) {
                const { exists } = await UserService.availability({ phone: value });
                if (exists) {
                    throw new Error('This phone number is already taken');
                }
            }
        }
    }),
    subdomainUnique: locationId => ({
        validator: async (data, value) => {
            if (RESERVED_SUBDOMAINS.includes(value)) {
                throw new Error('This name is already taken. Please try another.');
            }
            let location = {};
            try {
                location = await LocationService.getByDomain(value);
            } catch (err) {
                return;
            }
            if (location.id !== locationId) {
                throw new Error('This name is already taken. Please try another.');
            }
        }
    }),
});

export {
    FieldTypes,
    ValidationRules
};
