import React from 'react';
/* 
Obsolete modules
export const MODULE_PANDEMIC = 1;
export const MODULE_VACCINATION = 7;
export const MODULE_TEST = 8;
export const MODULE_SCREEN = 10;
export const MODULE_ATTENDANCE = 5; // hidden, not used
export const MODULE_REPORTS = 4;
export const MODULE_INJURY_REPORT = 6;
export const MODULE_INCIDENT_REPORTING = 9;
еxport const MODULE_TELEHEALTH = 11;
export const MODULE_IMMUNIZATION = 16;
export const MODULE_LIFEWORKS = 13;
*/

export const MODULE_PERMISSION = {
    NONE: 0,
    VIEW: 1,
    EDIT: 2,
};

export const MODULE_INCIDENT = 20;
export const MODULE_WC_INCIDENT = 12;
export const MODULE_AUTO_INCIDENT = 17;
export const MODULE_LIABILITY_INCIDENT = 18; 
export const MODULE_PROPERTY_INCIDENT = 19; 
export const MODULE_NON_REVENUE_INCIDENTS = 21; // MODULE_STUDENT_INCIDENT
export const MODULE_ACCESS_ASSISTANCE_INCIDENT = 22;
export const MODULE_HP_LIABILITY_INCIDENT = 23; 
export const MODULE_WORKPLACE_VIOLENCE_INCIDENT = 24;
export const MODULE_MESSAGE_CENTER_INCIDENT = 25;
export const MODULE_LIN_DIRECT_INCIDENT = 26;

export const MODULE_ALERTS = 2;
export const MODULE_BENEFITS = 3;
export const MODULE_INTEGRITY_LINE = 14;
export const MODULE_WECONNECT = 15;

const Incidents = React.lazy(() => import('routes/administration/routes/incidents'));
const IncidentsWC = React.lazy(() => import('routes/administration/routes/incidents/list/listWc'));
const IncidentsAuto = React.lazy(() => import('routes/administration/routes/incidents/list/listAuto'));
const IncidentsLiability = React.lazy(() => import('routes/administration/routes/incidents/list/listLiability'));
const IncidentsProperty = React.lazy(() => import('routes/administration/routes/incidents/list/listProperty'));
const IncidentsAccessAssistance = React.lazy(() => import('routes/administration/routes/incidents/list/listAccessAssistance'));
const IncidentsHighProfileLiability = React.lazy(() => import('routes/administration/routes/incidents/list/listHighProfileLiability'));
const IncidentsWorkplaceViolence = React.lazy(() => import('routes/administration/routes/incidents/list/listWorkplaceViolence'));
const IncidentsMessageCenter = React.lazy(() => import('routes/administration/routes/incidents/list/listMessageCenter'));
const IncidentsLinDirect = React.lazy(() => import('routes/administration/routes/incidents/list/listLinDirect'));
const IncidentsNonRevenue = React.lazy(() => import('routes/administration/routes/incidents/list/listNonRevenue'));
const Alerts = React.lazy(() => import('routes/administration/routes/alerts'));
const IntegrityLine = React.lazy(() => import('routes/administration/routes/integrityLine'));
/* VisibleOn
userMenu: user/patient edit, submenu,
locationMenu: location edit, submenu,
adminPermissions: edit admin, assign permission,
adminListPermissions - admin list, modules columns
locationList - location list, modules column + icons,
sysAdminOnly - incidents submenu, visible for sysadmim only
*/

export const ModulesConfig = {
    incident: {
        module: 'incident',
        id: MODULE_INCIDENT,
        title: 'Incidents',
        desc: 'Incidents Module',
        route: '/incidents',
        menu: 'incident',
        component: Incidents,
        visibleOn: {
            locationMenu: true,
            adminPermissions: true,
        },
    },
    incidentWc: {
        module: 'incidentWc',
        parentId: MODULE_INCIDENT,
        id: MODULE_WC_INCIDENT,
        component: IncidentsWC,
        title: 'Workers` Compensation',
        desc: 'WC Incidents Module',
        route: '/I',
        visibleOn: {
            ladminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentAuto: {
        module: 'incidentAuto',
        parentId: MODULE_INCIDENT,
        id: MODULE_AUTO_INCIDENT,
        component: IncidentsAuto,
        title: 'Auto',
        desc: 'Auto Incidents Module',
        route: '/A',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentLiability: {
        module: 'incidentLiability',
        parentId: MODULE_INCIDENT,
        id: MODULE_LIABILITY_INCIDENT,
        component: IncidentsLiability,
        title: 'Liability',
        desc: 'Liability Incidents Module',
        route: '/L',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    /*
    @COMMENT Should be hidden for DS portal users */
    incidentNonRevenue: {
        module: 'incidentNonRevenue',
        parentId: MODULE_INCIDENT,
        id: MODULE_NON_REVENUE_INCIDENTS,
        component: IncidentsNonRevenue,
        title: 'Non Revenue',
        desc: 'NonRevenue Incidents Module',
        route: '/NR',
        roles: ['SYS_ADMIN'],
        visibleOn: {
            userMenu: false,
            locationMenu: false,
            adminPermissions: false,
            adminListPermissions: false,
            locationList: false,
        },
    }, 
    incidentProperty: {
        module: 'incidentProperty',
        parentId: MODULE_INCIDENT,
        id: MODULE_PROPERTY_INCIDENT,
        component: IncidentsProperty,
        title: 'Property',
        desc: 'Property Incidents Module',
        route: '/P',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentAccessAssistance: {
        module: 'incidentAccessAssistance',
        parentId: MODULE_INCIDENT,
        id: MODULE_ACCESS_ASSISTANCE_INCIDENT,
        component: IncidentsAccessAssistance, 
        title: 'Access Assistance',
        desc: 'Access Assistance Incidents Module',
        route: '/AA',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentHighProfileLiability: {
        module: 'incidentHighProfileLiability',
        parentId: MODULE_INCIDENT,
        id: MODULE_HP_LIABILITY_INCIDENT,
        component: IncidentsHighProfileLiability, 
        title: 'High Profile Liability',
        desc: 'High Profile Liability Incidents Module',
        route: '/HP',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentWorkplaceViolence: {
        module: 'incidentWorkplaceViolence',
        parentId: MODULE_INCIDENT,
        id: MODULE_WORKPLACE_VIOLENCE_INCIDENT,
        component: IncidentsWorkplaceViolence, 
        title: 'Workplace Violence',
        desc: 'Workplace Violence Incidents Module',
        route: '/WV',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentMessageCenter: {
        module: 'incidentMessageCenter',
        parentId: MODULE_INCIDENT,
        id: MODULE_MESSAGE_CENTER_INCIDENT,
        component: IncidentsMessageCenter, 
        title: 'Message Center',
        desc: 'Message Center Module',
        route: '/MC',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    incidentLinDirect: {
        module: 'incidentLinDirect',
        parentId: MODULE_INCIDENT,
        id: MODULE_LIN_DIRECT_INCIDENT,
        component: IncidentsLinDirect, 
        title: 'Lin Direct',
        desc: 'Lin Direct',
        route: '/D',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    alerts: {
        module: 'alerts',
        id: MODULE_ALERTS,
        component: Alerts, 
        title: 'Alerts & Newsfeed',
        desc: 'Alerts/Newsfeed Module',
        route: '/alerts',
        visibleOn: {
            userMenu: true,
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    benefits: {
        module: 'benefits',
        id: MODULE_BENEFITS,
        title: 'Benefits',
        desc: 'Benefits Module',
        route: '/benefits',
        visibleOn: {
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    integrityLine: {
        module: 'integrityLine',
        id: MODULE_INTEGRITY_LINE,
        title: 'Integrity Line',
        desc: 'Integrity Line module',
        route: '/integrityLine',
        component: IntegrityLine,
        visibleOn: {
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    WEconnect: {
        module: 'WEconnect',
        id: MODULE_WECONNECT,
        title: 'WEconnect',
        desc: 'WEconnect module',
        route: '/WEconnect',
        visibleOn: {},
    },
    /*
    lifeworks: {
        module: 'lifeworks',
        id: MODULE_LIFEWORKS,
        title: 'LifeWorks',
        desc: 'LifeWorks Module',
        route: '/lifeworks',
        visibleOn: {
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    },
    immunization: {
        module: 'immunization',
        id: MODULE_IMMUNIZATION,
        title: 'Immunizations',
        desc: 'Immunization Module',
        route: '/immunizations',
        component: Immunizations,
        visibleOn: {
            userMenu: true,
            locationMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
        onMenuItemClick: () => {
            GA.sendAdminEvent('Immunization Module: Menu Item Click', 'Immunization');
        },
        onActivate: () => {
            GA.sendAdminEvent('Immunization Module: Enabled', 'Immunization');
        },
        onDisactivate: () => {
            GA.sendAdminEvent('Immunization Module: Disabled', 'Immunization');
        },
    },
    attendance: {
        module: 'attendance',
        id: MODULE_ATTENDANCE,
        title: 'Attendance',
        desc: 'Attendance Module',
        route: '/attendance',
        visibleOn: {
            userMenu: true,
            adminPermissions: true,
            adminListPermissions: true,
            locationList: true,
        },
    }, */
};

export const IncidentModuleRoute = ModulesConfig.incident.route;

export const getIncidentSubmodules = () => Object.values(ModulesConfig)
    .filter(item => item.parentId === MODULE_INCIDENT);
