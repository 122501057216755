// @Usage: Admin & ScreenCn

/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-autofocus */
import './Code.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

export const Code = ({ 
    size, 
    onChange,
    disabled, 
    className = '', 
    autoFocus = false, 
    isValid = true, 
    initialValue = '' 
}) => {
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        const emptyArray = Array.create(size)
                .map(i => (initialValue[i] || ''));

        setInputs(emptyArray);
    }, [initialValue, size]);

    useEffect(() => {
        const fullValue = inputs.join('');
        if (onChange) {
            const valid = fullValue.length === size;
            onChange(valid ? fullValue : undefined);  
        }
    }, [inputs, size, onChange]);

    const textInputs = [];

    const handleChange = (e) => {
        const { id } = e.target.dataset;
        let value = String(e.target.value);

        value = value.replace(/[^\d]/g, '');

        if (value !== '') {
            const input = inputs.slice();

            if (value.length > 1) {
                value.split('').map((chart, i) => {
                    if (Number(id) + i < size) {
                        input[Number(id) + i] = chart;
                    }
                    return false;
                });
            } else {
                input[Number(id)] = value;
            }

            input.map((s, i) => {
                if (textInputs[i]) {
                    textInputs[i].value = s;
                }
                return false;
            });

            const newTarget = textInputs[id < input.length
                ? Number(id) + 1
                : id];

            if (newTarget) {
                newTarget.focus();
                newTarget.select();
            }

            setInputs(input);
        }
    };

    const handleKeyDown = (e) => {
        const target = Number(e.target.dataset.id);
        const next = textInputs[target + 1];
        const prev = textInputs[target - 1];

        let input;

        switch (e.keyCode) {
            case BACKSPACE_KEY:
                e.preventDefault();
                textInputs[target].value = '';
                input = inputs.slice();
                input[target] = '';
                setInputs(input);

                if (textInputs[target].value === '') {
                    if (prev) {
                        prev.focus();
                        prev.select();
                    }
                }
                break;
            case LEFT_ARROW_KEY:
                e.preventDefault();
                if (prev) {
                    prev.focus();
                    prev.select();
                }
                break;
            case RIGHT_ARROW_KEY:
                e.preventDefault();
                if (next) {
                    next.focus();
                    next.select();
                }
                break;
            case UP_ARROW_KEY:
            case DOWN_ARROW_KEY:
            case E_KEY: // Prevent entering the floating-point numbers
                e.preventDefault();
                break;
            default:
                break;
        }
    };
    
    return (
        <div className={`${className} input-code`}>
            {inputs.map((value, i) => (
                <div 
                    key={`col_${i}`}
                >
                    <input
                        ref={(ref) => {
                            textInputs[i] = ref;
                        }}
                        data-id={i}
                        autoFocus={autoFocus && (i === 0)}
                        value={value}
                        key={`input_${i}`}
                        type="number"
                        min={0}
                        max={9}
                        maxLength={1}
                        autoComplete="new-password"
                        onFocus={e => e.target.select(e)}
                        onChange={e => handleChange(e)}
                        onKeyDown={e => handleKeyDown(e)}
                        disabled={disabled}
                        data-valid={isValid}
                        inputMode="numeric"
                        className="ant-input"
                    />
                </div>
            ))}
        </div>
    );
};

Code.propTypes = {
    size: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    initialValue: PropTypes.string,
    className: PropTypes.string,
    isValid: PropTypes.bool,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
};
