import './attachments.scss';
import React, { useState, useEffect, forwardRef } from 'react';
import { Spin, Button, FileUpload, FormItem } from 'library';
import PropTypes from 'prop-types';
import fileSVG from 'assets/images/file.svg';
import { ValidationRules as rules } from 'utils';

const Attachments = forwardRef(({
    id, 
    actions, 
    required = false, 
    label = 'Attachments', 
    maxCount = 3, 
    accept = '.pdf, .xls, .xlsx, .doc, .docx, .jpg, .jpeg, .png, .heif'
}, ref) => {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        if (id && actions.getAttachments) {
            setLoading(true);
            actions.getAttachments(id)
                .then(({ data }) => setFiles(data))
                .finally(() => setLoading(false));
        }
    }, [actions, id]);

    const onDownload = fileId => actions.downloadFile(fileId);

    const save = (values = { id }) => {
        if (attachments.length && actions.attach) {
            const formData = new FormData();
            attachments.forEach((file) => {
                formData.append('files[]', file);
            });
            return actions.attach(values.id, formData);
        }
        return Promise.resolve({});
    };

    // Public methods
    React.useImperativeHandle(ref, () => ({
        save,
        changed: attachments.length > 0
    }));

    return (
        <Spin spinning={loading}>
            <FormItem
                label={label}
                name="files"
                tooltip={`Supported file types:  ${accept}`}
                rules={required ? [rules.required] : []}
            >
                <FileUpload
                    onChange={setAttachments}
                    maxCount={maxCount}
                    label="Tap to upload"
                    accept={accept}
                    disabled={files.length >= maxCount}
                />
            </FormItem>
            {!!files.length && (
                <div className="attachments">
                    {files.map(i => (
                        <div key={i.id}>
                            <img src={fileSVG} alt="file" />
                            <span>{i.name} </span>
                            <Button
                                type="default"
                                size="small"
                                onClick={() => onDownload(i.id)}
                            >Preview
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </Spin>
    );
});

Attachments.propTypes = {
    id: PropTypes.number,
    actions: PropTypes.func.isRequired,
    required: PropTypes.bool,
    maxCount: PropTypes.number,
    accept: PropTypes.string,
    label: PropTypes.string
};

export { Attachments };
