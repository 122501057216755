export const NOTIFICATION_CHANNEL = {
    app: 'alert',
    sms: 'sms',
    email: 'email'
};

export const NOTIFICATION_TYPE = {
    custom: 'CUSTOM',
    reminder: 'REMINDER',
    importReady: 'IMPORT_READY',
    exportReady: 'EXPORT_READY'
};

export const NOTIFICATION_INLINE = {
    exportStart: {
        id: 'ExportReady',
        message: 'Please hang in there while we are preparing the export for you.'
    },
    importStart: {
        id: 'ImportReady',
        message: 'Please hang in there while we are importing users for you.'
    },
};

export const LOCAL_KEY = 'notificationShownIds';
export const BULK_NOTIFICATION_TYPE = {
    admins: 'Admin',
};

export const NOTIFICATION_LINKS = [
    {
        key: 'home',
        label: 'Homepage',
        text: 'Homepage',
        value: '',
    },
    {
        key: 'alerts',
        label: 'Alerts',
        text: 'Alerts',
        value: '/alerts',
    },
    {
        key: 'benefits',
        label: 'Benefits',
        text: 'Benefits',
        value: '/benefits',
    },
];
