import './index.scss';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BrandContext } from 'brands';

const Logo = ({ className = '', useLocation }) => {
    const { name, useLogo } = useSelector(state => state.location);
    const { logo: brandLogo, name: brandName } = useContext(BrandContext);
    const src = useLocation ? useLogo || brandLogo : brandLogo;
    const title = useLocation ? name || brandName : brandName;

    return (
        <img
            className={`brand-logo ${className}`}
            src={src}
            alt={title}
            title={title}
        />
    );
};

Logo.propTypes = {
    className: PropTypes.string,
    useLocation: PropTypes.bool
};

export {
    Logo,
};
