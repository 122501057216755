import { message as AntDMessage } from 'antd';

export const showError = (error, duration = 10) => {
    if (error && (error.$message || error.message)) {
        if (Object.isDefined(error.$message) && error.$message === false) {
            return false;
        }
        const text = error.$message || error.message;
        AntDMessage.error(text, duration);
        return {};
    }
    return (error);
};

export const showMsg = (text, type = 'info', duration = 10) => {
     AntDMessage[type](text, duration);
};
