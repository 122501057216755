/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { message } from 'library';

const useRequest = (service, initialData) => {
    const [data, setData] = useState(initialData);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetchMethod = (...args) => {
        setLoading(true);
        return service(...args)
            .then(setData)
            .catch((err) => {
                setError(err);
                message.error(err.message || 'Unable to load data!');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, []);

    return [stableFetch, { data, loading, error }];
};

export { useRequest };
