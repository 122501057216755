import fitness from 'assets/images/benefits/fitness.svg';
import walking from 'assets/images/benefits/walking-outside.svg';
import health from 'assets/images/benefits/health.svg';
import hiking from 'assets/images/benefits/hiking.svg';
import online from 'assets/images/benefits/online-world.svg';
import voice from 'assets/images/benefits/voice-interface.svg';
import ideas from 'assets/images/benefits/forming-ideas.svg';
import answer from 'assets/images/benefits/answer.svg';
import nature from 'assets/images/benefits/nature.svg';
import documents from 'assets/images/benefits/documents.svg';

export const ICON_TYPE_FILENAME = {
    fitness,
    walking,
    health,
    hiking,
    online,
    voice,
    ideas,
    answer,
    nature,
    documents
};

export const COLOR_FILENAME = [
    '#BCC4E9',
    '#CDD8E2',
    '#E5E1E5',
    '#BFD7D9',
    '#F2E9D0',
    '#E8E6E5',
    '#C5E8FC',
    '#E1D8F6',
    '#E6EFD9',
    '#CEE8DA'
];
