import './Empty.scss';
import React from 'react';
import PropTypes from 'prop-types';
import EmptyImage from 'assets/images/ico.empty.svg';

const Empty = ({ description, className = '' }) => (
    <div className={`empty ${className}`}>
        <img src={EmptyImage} alt="Empty" />
        <span>{description} </span>
    </div>
);

Empty.propTypes = {
    description: PropTypes.any.isRequired,
    className: PropTypes.string
};

export { Empty };
