import './TreeSelect.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Multiselect } from '../Multiselect';
import Dropdown from './dropdown';

const TreeSelectDropdown = ({
    value = [], 
    setVisible, 
    loading = false,
    options = [], 
    onChange = () => {}, 
    label = '', 
    searchLabel = ''
}) => {
    const onDone = () => {
        setVisible(false);
    };

    return (
        <Dropdown
            value={value}
            options={options}
            onDone={onDone}
            onChange={onChange}
            label={label}
            searchLabel={searchLabel}
            loading={loading}
        />
    );
};

TreeSelectDropdown.propTypes = {
    value: PropTypes.array,
    setVisible: PropTypes.func, 
    loading: PropTypes.bool,
    options: PropTypes.array, 
    onChange: PropTypes.func, 
    label: PropTypes.string, 
    searchLabel: PropTypes.string
};

const TreeSelect = ({
    options, 
    value = [],
    onChange = () => { }, 
    label = '', searchLabel = '',
    loading, variant, maxTagCount = 'responsive',
    disabled = false, 
    getPopupContainer = trigger => trigger.parentNode
}) => {
    const [visible, setVisible] = useState(false);

    const handleClick = (event) => {
        if (event.button === 0 && visible === false) setVisible(true);
    };
    return (
        <Multiselect
            className="tree-multiselect"
            disabled={disabled}
            value={value}
            onChange={onChange}
            options={options}
            loading={loading}
            placeholder={`Please Select ${label}`}
            maxTagCount={maxTagCount}
            variant={variant}
            popupClassName="tree-multiselect-dropdown"
            open={visible}
            onClick={e => handleClick(e)}
            getPopupContainer={getPopupContainer}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={() => (
                <Dropdown
                    value={value}
                    options={options}
                    onDone={() => setVisible(false)}
                    onChange={onChange}
                    label={label}
                    searchLabel={searchLabel}
                    loading={loading}
                />
            )}
        />
    );
};

TreeSelect.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    variant: PropTypes.bool,
    maxTagCount: PropTypes.any,
    searchLabel: PropTypes.string,
    disabled: PropTypes.bool,
    getPopupContainer: PropTypes.func
};

export { TreeSelect };
