import React from 'react';
import PropTypes from 'prop-types';
import './moduleInfo.scss';
import { TabsBase } from 'library';
import { EditableContent } from 'routes/administration/containers';

const EditableContentTab = ({ options }) => {
    const items = options.map(item => ({
        key: item.key,
        label: item.tab,
        disabled: item.disabled,
        children: (
            <div className={`page-scroll module-info module-info-${item.module}`}>
                <EditableContent
                    name={item.name}
                    editable={item.editable}
                    className="editable-tab-content"
                />
            </div>
        )
    }));
    const activeKey = options.find(item => !item.disabled)?.key;
    return (
        <TabsBase
            className="module-info-tabs"
            items={items}
            defaultActiveKey={activeKey}
        />
    );
};

EditableContentTab.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            editable: PropTypes.any.isRequired,
            name: PropTypes.any.isRequired,
            onClick: PropTypes.func.isRequired,
            tab: PropTypes.any.isRequired,
            key: PropTypes.any.isRequired,
            module: PropTypes.string.isRequired
        })
    ).isRequired
};

export default EditableContentTab;
