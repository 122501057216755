import React, { useContext } from 'react';
import { BrandContext } from '../../context';

const TermsLink = () => {
    const { termsLink } = useContext(BrandContext);
    
    return (
        <a
            className="terms"
            href={termsLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            Terms of Use
        </a>
    );
};

const PolicyLink = () => {
    const { privacyLink } = useContext(BrandContext);
    return (
        <a
            className="privacy"
            href={privacyLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            Privacy Policy
        </a>
    );
};

export { PolicyLink, TermsLink };
