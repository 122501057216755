import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spin } from 'library';
import { hasGrant } from 'security';
import { LayoutConstants } from 'modules/layout';
import AccessDenied from 'routes/administration/routes/accessDenied';
import ModuleInfo from 'routes/administration/routes/moduleInfo';

import { ROLE_SYS_ADMIN, ROLE_ADMIN } from 'modules/user';

// Lintelio administration
const PrivateRoute = ({ 
    component: Component, 
    module, 
    roles = [ROLE_SYS_ADMIN, ROLE_ADMIN], 
    menu, 
    ...rest 
}) => {
    const user = useSelector(state => state.user);
    const location = useLocation();
    const dispatch = useDispatch();
    const { computedMatch } = rest;
    const { $locationModules = {}, $loading, $modules: $userModules } = user;
   
    useEffect(() => {
        if (Object.isDefined(menu)) {
            dispatch({ 
                type: LayoutConstants.ADMIN_SUBMENU, 
                submenu: menu,
                params: computedMatch,
            });
        }
    }, [computedMatch, dispatch, menu]);
       
    if ($loading) {
        return <Spin type="center" />;
    }
    
    if (roles && !hasGrant(roles, user)) {
        return <AccessDenied />;
    }

    if (module) {
        const toEditModule = location.state?.moduleInfo;
        if (toEditModule) {
            return <ModuleInfo module={toEditModule} />;
        }

        if (!$userModules[module]) {
            return <AccessDenied />;
        }
        if (!$locationModules[module]) {
            return <ModuleInfo module={module} />;
        }
    }
    
    if (!Component) {
        return <ModuleInfo module={module} hasContent={false} />;
    }
      
    return React.createElement(Component);
};

PrivateRoute.propTypes = {
    component: PropTypes.any,
    module: PropTypes.string,
    roles: PropTypes.array,
    menu: PropTypes.string
};

export { PrivateRoute };
