/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showError } from 'utils';
import { ShowMessage } from 'services';
import { LocationService } from '../location.service';
import { LocationConstants } from '../location.constants';

export const showLocationUpdateMessage = ($toLocationIds) => {
    ShowMessage.saved();
    if ($toLocationIds && $toLocationIds.length) {
        ShowMessage.info('Copy down started. Please hang in there while we are updating locations for you.');
    }
};

export const useLocationUpdate = ({ skipMessage } = {}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetchMethod = (id, values, getTranslations) => {
        const {
            announcements,
            $toLocationIds,
            defaultHiddenQuestions,
            immunizationTypes,
            ...rest
        } = values;

        const promises = [];
        if (!Object.isEmpty(rest) || getTranslations) {
            promises.push(LocationService.update(id, { ...rest, $toLocationIds }, getTranslations)
                .then((data) => {
                    dispatch({
                        type: LocationConstants.LOADED_IN_ADMIN,
                        data
                    });
                }));
        }

        if (announcements) {
            promises.push(LocationService.announcements(id, {
                data: announcements,
                $toLocationIds
            }));
        }

        setLoading(true);
        return Promise.all(promises)
            .then((data) => {
                if (!skipMessage) {
                    showLocationUpdateMessage($toLocationIds);
                }
                return data;
            })
            .catch((err) => {
                setError(err);
                showError(err);
                return Promise.reject(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stableFetch = useCallback(fetchMethod, []);
    return [stableFetch, { loading, error }];
};
