import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { stopEvent } from 'utils';
import { useNavigate } from 'routing';
import { EditFilled } from '@ant-design/icons';

const ButtonModuleInfo = ({ route, module }) => {
    const { $isSysAdmin } = useSelector(state => state.user);
    const navigate = useNavigate();
    
    const onClick = (e) => {
        stopEvent(e);
        navigate(route, { state: { moduleInfo: module, route }, replace: true });
    };
    if (!$isSysAdmin) {
        return null;
    }
    return (
        <EditFilled 
            onClick={onClick}
        />
    );
};

ButtonModuleInfo.propTypes = {
    route: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired
};

export { ButtonModuleInfo };
