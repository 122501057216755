import './copy.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'library';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { copyCodeToClipboard } from 'utils';

const defaultCopied = { value: false, el: '' };

const CopyButton = ({ name, value, type = 'link', children = null }) => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied && copied.value) {
            setCopied({ ...copied, value: false });
            message.success('Copied', 3, () => setCopied(defaultCopied));
        }
    }, [copied]);

    const isCopied = (el) => {
        if (copied && el === copied.el) return true;
        return false;
    };

    const onClick = () => {
        const val = copyCodeToClipboard('.qr-svg > canvas', value);
        setCopied({ value: val, el: name });
    };

    const ready = isCopied(name);
    return (
        <Button 
            className="copy-btn"
            onClick={onClick}
            type={type}
            title="Click to copy to Clipboard"
            icon={ready ? <CheckOutlined /> : <CopyOutlined onClick={onClick} />}
        >
            {children}
        </Button>
    );
};

CopyButton.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    type: PropTypes.string,
    children: PropTypes.any,
};

export { CopyButton };
