import './Multiselect.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'library';

const Multiselect = ({
        options = [], 
        value = [], 
        onChange,
        className = '',
        allowClear = true, 
        showSearch = false, 
        mode = 'multiple', 
        getPopupContainer = () => document.body,
        valuePropName = 'id',
        ...rest
    }) => {
    const [open, setOpen] = useState(false);
    const [currentOptions, setCurrentOptions] = useState(options);

    useEffect(() => {
        const ops = [...options];
        setCurrentOptions(ops);
    }, [options]);

    const onDropdownVisibleChange = () => {
        setOpen(!open);
    };

    const onSearch = (inputValue) => {
        const filtered = options.filter(
            ({ label }) =>
                label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setCurrentOptions(filtered);
    };

    const onBeforeChange = (ids) => {
        if (onChange) {
            const compareTo = mode === 'multiple' ? ids : [ids];
            const items = options.filter(item => compareTo.includes(item[valuePropName]));
            onChange(ids, items);
        }
    };

    const classNm = `${mode === 'multiple' ? 'multiselect' : ''} ${className} ${showSearch ? 'ant-select-show-search' : ''}`;

    const props = {
        value,
        options: currentOptions,
        onChange: onBeforeChange,
        allowClear,
        showSearch,
        filterOption: false,
        onSearch: showSearch ? onSearch : false,
        mode,
        className: classNm,
        onDropdownVisibleChange,
        open,
        getPopupContainer,
        ...rest
    };
    return <Select {...props} />;
};

Multiselect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowClear: PropTypes.bool,
    showSearch: PropTypes.bool,
    className: PropTypes.string,
    mode: PropTypes.string,
    getPopupContainer: PropTypes.func,
    valuePropName: PropTypes.string
};

export { Multiselect };
