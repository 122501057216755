import './upload.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Upload } from 'antd';
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { isMobile } from 'utils';
import { message } from '../AntD';

const { Dragger } = Upload;

const MAX_FILE_SIZE_MB = 10;

const FileUpload = ({ maxCount = 1, onChange, label = 'Tap to Upload', value, accept, ...rest }) => {
    const [fileList, setFileList] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (errors.length) {
            const uniqueErrors = [...new Set(errors)];
            uniqueErrors.map(i => message.error(i));
            setErrors([]);
        }
    }, [errors, errors.length]);

    const beforeUpload = (file, files) => {
        let list = [...fileList, ...files];

        const lengthBeforeFiltering = list.length;
        
        list = list.filter(({ size }) => {
            const sizeMB = size / (1000 * 1000);
            if (sizeMB <= MAX_FILE_SIZE_MB) return true;
            setErrors(oldState => [...oldState, 'File size bigger than 10MB is not allowed']);
            return false;
        });
        
        if (lengthBeforeFiltering > maxCount) {
            list = list.slice(0, maxCount);
            setErrors(oldState => [...oldState, 'Max file count has been exceeded']);
        }

        const extension = file.name.split('.').pop();
        if (!accept.includes(`.${ extension}`)) {
            message.error(`Supported file types are: ${ accept}`);
        } else {
            setFileList(list);
            onChange(list);
        }
        return false;
    };

    const onRemove = (file) => {
        const list = Array.remove(fileList, file);
        setFileList(list);
        onChange(list);
    };

    const thisProps = {
        beforeUpload,
        onRemove,
        maxCount,
        fileList,
        multiple: maxCount > 1,
        accept,
        ...rest,
    };

    if (!isMobile) {
        return (
            <Dragger {...thisProps}>
                <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag file{maxCount > 1 ? 's' : ''} to this area to upload
                </p>
                <p className="ant-upload-hint">
                    The limit is {maxCount > 1 ? `${maxCount } files and` : ''}  {MAX_FILE_SIZE_MB}MB per file
                </p>
            </Dragger>
        );
    }
    return (
        <Upload {...thisProps}>
            <Button
                block
                icon={<UploadOutlined />}
            >{label}
            </Button>
        </Upload>
    );
};

FileUpload.propTypes = {
    label: PropTypes.string,
    maxCount: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    accept: PropTypes.string.isRequired
};

export { FileUpload };
