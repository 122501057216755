/* eslint-disable class-methods-use-this */
import { api } from 'services';
import { UserService } from 'modules/user';
import { NOTIFICATION_CHANNEL } from './notification.model';
import Transformer from './transformers';

class NotificationService {
    static bulkCreate(data) { 
        const postData = Transformer.out(data);
        return api.post('/customNotifications', postData);
    }
    
    static bulkList(...args) { 
        return api.list('/customNotifications', ...args)
            .then(Transformer.list);
    }
    
    // generic user list
    static list = (page, perPage, fs = {}) => {
        const filters = {
            userId: UserService.id,
            ...fs,
        };

        return api.list('/notifications', page, perPage, filters)
            .then(Transformer.list);
    };
        
    static update = (id, data) => api.put(`/notifications/${id}`, data);

    // get current user history 
    static getAlersHistory() {
        return this.list(1, 1000, { 
            archivedAt: { pattern: 'NULL' },
            channel: NOTIFICATION_CHANNEL.app
        });
    }
    
    static getAlertsUnread() { 
        return this.list(1, 10, { 
            readAt: { pattern: 'NULL' }, 
            archivedAt: { pattern: 'NULL' },
            channel: NOTIFICATION_CHANNEL.app
        });
    }

    static getAlertsArchived() {
        return this.list(1, 1000, { 
            archivedAt: { pattern: 'NULL', not: true },
            channel: NOTIFICATION_CHANNEL.app
        });
    }

    static markRead(id) {
        return api.post(`/notifications/${id}`);
    }

    static delete(id) {
        return api.delete(`/notifications/archived/${id}`);
    }

    static download(...args) {
        return api.download(...args);
    }
    
    static listByPatient({ patientId, page, take, filters, sort }) {
        return api.list(`/patients/${patientId}/notifications`, page, take, filters, sort)
            .then(Transformer.list);
    }
}

export { NotificationService }; 
