/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './resizableHeader.scss';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 'react-resizable';
import Context from './context';

const ResizableHeader = (props) => {
    const { width, index, ...restProps } = props;
    const { columns, setColumns, updateUserPrefs } = useContext(Context);
        
    const onResize = (e, { size }) => {
        const minWidth = columns[index].filter ? 80 : 60;
        if (size.width < minWidth) {
            return;
        }
        const nextColumns = [...columns];
        nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
        };
        setColumns(nextColumns);
        updateUserPrefs(nextColumns);
    };

    if (!width || !index) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            height={0}
            onResize={onResize}
            width={width}
            handle={(
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            )}
        >
            <th {...restProps} />
        </Resizable>
    );
};

ResizableHeader.propTypes = {
    width: PropTypes.any,
    index: PropTypes.number
};

export default ResizableHeader;
