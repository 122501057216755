import 'assets/styles/index.scss';
import React, { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { ConfigProvider as AntdConfigProvider, App } from 'antd';
import { EscapeAntd, Spin } from 'library';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'modules/store';
import { BrandProvider } from 'brands';
import { antdTheme } from 'brands/lintelio/antd.theme';
import { Router } from 'routing';
import { ServiceWorkerProvider } from './providers/serviceWorker';
import Routes from './routes';

const GoogleAnalyticsKey = process.env.REACT_APP_GA;

const LintelioDS = () => {
    useEffect(() => {
        if (GoogleAnalyticsKey) {
            ReactGA.initialize(GoogleAnalyticsKey, {
                debug: false,
                titleCase: false,
            });
        }
    }, []);

    return (
        <ReduxProvider store={store}>
            <BrandProvider>
                <AntdConfigProvider {...antdTheme}>
                    <App notification={{ showProgress: true }}>
                        <Suspense fallback={<Spin fullscreen />}>
                            <Router>
                                <EscapeAntd />
                                <Routes />
                            </Router>
                        </Suspense>
                    </App>
                </AntdConfigProvider>
            </BrandProvider>
        </ReduxProvider>
    );
};

const container = document.getElementById('ds-app');
const root = createRoot(container);
root.render(<ServiceWorkerProvider><LintelioDS /></ServiceWorkerProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
