export default {
    // General
    timezone: 'Time Zone',
    temperatureUnit: 'Temperature Unit',
    emergencyNumber: 'Emergency Number',
    whiteLabelPermission: 'Allow White Labeling',
    whiteLabelReminders: 'Display Logo in Email Reminders',
    whiteLabelAlerts: 'Display Logo in Email Alerts',
    showBenefitsTile: 'Show Benefits Tile',

    // White label
    color: 'Color',
    logo: 'Logo',

    // Authentication
    tokenValidityHours: 'Token Validity',

    // Registration settings
    isSelfRegistrationEnabled: 'Disable Self-registration',

    flowType: 'Type of flow',
    tempCheck: 'Is Temperature Included',
    temperatureThreshold: 'Temperature Threshold',
    exposureDays: 'Exposure Period (duration in days)',
    isScreeningDetailsEnabled: 'View Screening Details',
    isScreenFullIntakeEnabled: 'Screen Full Intake',
    isSymptomPopupHidingDisabled: 'Disable Symptom Popup Hiding',

    // Incident
    incidentPullIntegrationId: 'Lintelio Enterprise Pull Integration ID',

    announcements: 'Homepage Newsfeed',
    benefits: 'Benefits',
    immunizationTypes: 'Immunization Vaccine Types',
    chargebeeSubscriptionId: 'Default Chargebee Subscription Id',
    chargebeeCustomerId: 'Chargebee Customer Id',

    smsSendingEnabled: 'Allow SMS Communication',
    emailSendingEnabled: 'Allow Email Communication',
    enableSmsAlertForAdmins: 'Enable SMS Alerts for Admins',
    
    /* @TODONEXT
    jobTitles: 'OccupationMapping',
    */
};

// List of location fields (not only updatable)
// which should be saved always
export const FieldsToKeep = [
    'registrationFields',
    'announcements',
    'questions',
    'hiddenQuestions',
    'benefits',
    'immunizationTypes'
];   
