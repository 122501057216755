import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, FormItem } from 'library';
import { EnterpriseLocationService } from 'modules/enterprise';
import { LocationSelect } from '../LocationSelect';

const LocationIntegrationId = ({ onChange, value = '', tooltip = '', label }) => {
    const [checked, setChecked] = useState(false);
    const { $isSysAdmin } = useSelector(state => state.user);

    useEffect(() => {
        setChecked(!!value);
    }, [value]);

    const onCheckedChange = (val) => {
        if (!val) {
            onChange('');
        }
        setChecked(val);
    };

    const validationRule = {
        validator: async (_, val) => {
            if (checked && !(val || value)) {
                throw new Error('Required Field');
            }
        }
    };

    return (
        <>
            <FormItem
                className="form-item-switch form-item-switch-inline"
                label={label}
                tooltip={tooltip}
            >
                <Switch
                    disabled={!$isSysAdmin}
                    checked={checked}
                    onChange={onCheckedChange}
                />
            </FormItem>
            <FormItem
                label="Lintelio Enterprise Integration ID"
                rules={[validationRule]}
                required={checked}
            >
                <LocationSelect
                    value={value}
                    service={EnterpriseLocationService}
                    valuePropName="locationHash"
                    onChange={onChange}
                    disabled={!checked || !$isSysAdmin}
                />
            </FormItem>
        </>
    );
};

LocationIntegrationId.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    tooltip: PropTypes.string,
    label: PropTypes.string.isRequired
};

export { LocationIntegrationId };
