import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { thunk as thunkMiddleware } from 'redux-thunk';
import location from './location/location.reducer';
import user from './user/user.reducer';
import layout from './layout/layout.reducer';
import alerts from './notification/notification.reducer';
import admin from './admin/admin.reducer';
import provider from './provider/provider.reducer';
import document from './document/document.reducer';

const RootReducer = combineReducers({
    location,
    user,
    layout,
    alerts,
    admin,
    provider,
    document
});

const store = createStore(
    RootReducer,
    compose(
        applyMiddleware(
            thunkMiddleware,
        ),
    )
);

export default store;
