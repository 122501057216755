/* eslint-disable no-console */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { unregister } from './serviceWorkerRegistrar';
// Initialize deferredPrompt for use later to show browser install prompt.
window.deferredPWAPrompt = undefined;

const ServiceWorkerProvider = ({ children }) => {
    useEffect(() => {
        // Service worker is disabled
        unregister();
    }, []);

    return children;
};

ServiceWorkerProvider.propTypes = {
    children: PropTypes.any.isRequired
};

export { ServiceWorkerProvider };
