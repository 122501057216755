import './help.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'library';
import { DownOutlined, QuestionCircleFilled, UpOutlined } from '@ant-design/icons';
import IcoBulkImport from 'assets/images/ico.import.png';
import BulkTemplateUsers from 'assets/files/bulk_upload_users_template.xls';
import BulkTemplateTests from 'assets/files/bulk_upload_tests_template.xlsx';
import BulkTemplateAdmins from 'assets/files/bulk_upload_admins_template.xlsx';
import { UsersGuidlineText, TestsGuidlineText, AdminsGuidlineText } from './guidelines';

const ImportConfig = {
    admins: {
        title: 'Admins',
        template: BulkTemplateAdmins,
        guidline: <AdminsGuidlineText />
    },
    users: {
        title: 'Users',
        template: BulkTemplateUsers,
        guidline: <UsersGuidlineText />
    },
    tests: {
        title: 'Tests',
        template: BulkTemplateTests,
        guidline: <TestsGuidlineText />
    }
};

const ImportHelp = ({ type }) => {
    const [visible, setVisible] = useState(false);
    const [guidline, setGuidline] = useState(false);

    const config = ImportConfig[type];

    const Header = (
        <div className="import-info-header">
            <img src={IcoBulkImport} alt="Bulk Import" />
            <div>
                <h1>
                    Bulk import
                </h1>
                <span>
                    Import multiple {config.title} using .XLS file format
                </span>
            </div>
            <Button
                type="link"
                icon={guidline ? <UpOutlined /> : <DownOutlined />}
                onClick={() => setGuidline(!guidline)}
            >
                View Guideline
            </Button>
        </div>
    );

    return (
        <>
            <QuestionCircleFilled onClick={() => setVisible(true)} />

            <Modal
                className="modal-import-info"
                title={Header}
                open={visible}
                okText="Got it"
                closable={false}
                onOk={() => setVisible(false)}
                cancelText="Download Template"
                cancelButtonProps={{ href: config.template }}
            >
                {guidline && config.guidline}
            </Modal>
        </>
    );
};

ImportHelp.propTypes = {
    type: PropTypes.oneOf(['users', 'admins', 'tests']).isRequired
};

export default ImportHelp;
